(function($) {
    $.widget('ogn.accordionclamp', {
        _create: function() {
            this.isIE11 = !Element.prototype.append;
            this.$text = $('> .card-header .text-clamp', this.element);
            this.hasTextClampt = this.$text.length === 1;
            if (this.hasTextClampt) {
                this.$collapse = $('> .collapse', this.element);
                this.hasCollapse = this.$collapse.length === 1;
            }
        },
        _init: function() {
            if (this.hasTextClampt && this.hasCollapse) {
                this._on(this.window, {
                    load: function() {
                        if (this.isIE11) return;
                        this.dotdotdot = new Dotdotdot(this.$text.get(0), {
                            height: 64,
                            truncate: "word",
                            watch: true,
                            ellipsis: "\u2026 "
                        })
                        this.$collapse.on('show.bs.collapse', this._onShow.bind(this));
                        this.$collapse.on('shown.bs.collapse', this._onShown.bind(this));
                        this.$collapse.on('hide.bs.collapse', this._onHide.bind(this))
                        this.$collapse.on('hidden.bs.collapse', this._onHidden.bind(this));
                    }
                })
            }
        },
        _onShow: function() {
            if (!this.isIE11) {
                this.dotdotdot.API.restore();
                this.dotdotdot.API.unwatch();
            }
            this.$text.addClass('showing');
        },
        _onShown: function() {
            this.$text.removeClass('showing');
            this.$text.addClass('shown');
        },
        _onHide: function() {
            this.$text.addClass('hiding');
        },
        _onHidden: function() {
            if (!this.isIE11) {
                this.dotdotdot.API.truncate();
                this.dotdotdot.API.watch();
            }
            this.$text.removeClass('hiding shown')
        }
    });
    $('.component-accordion .card').accordionclamp();
})(jQuery);