var CONTACT_FORM_SPECIALTIES = {
    'dermatologist': 'Dermatologist',
    'podiatrist': 'Podiatrist',
    'plastic-surgeon': 'Plastic Surgeon',
    'general-surgeon': 'General Surgeon',
    'vascular-surgeon': 'Vascular Surgeon',
    'gerontologist': 'Gerontologist',
    'primary-care-physician': 'Primary Care Physician',
    'internal-medicine': 'Internal Medicine',
    'nurse': 'Nurse',
    'hospitalist': 'Hospitalist',
    'other': 'Other'
};

var CONTACT_FORM_REASON = {
    'local-specialist': 'Contact from a local Organogenesis Tissue Generation Specialist',
    'reimbursement': 'Reimbursement / Insurance Inquiry',
    'product-information': 'Product Information',
    'product-order': 'Product Order or Delivery Support',
    'medical-consultation': 'Medical or Technical Consultation',
    'patient-assistance': 'Patient Assistance Information',
    'general-question': 'General Question',
    'website-feedback': 'Website Feedback'
}
var isValidString = function(value) {
    return !!value && value.length > 0;
};
var isValidEmail = function(value) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
};
var isValidPhone = function(value) {
    var re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return !!value && value.length > 0 && re.test(value);
};
var isValidZip = function(value) {
    return !!value && value.length >= 5;
};
var offsetNavigationHeight = 112;

var ContactForm = function(selector) {
    this.errors = false;
    this.selector = selector;
    this.$container = this.elements.container(selector);
    this.$container.on('success', this.containerSuccess);
    this.$form = this.elements.form(this.$container);
    this.$form.submit(this.onFormSubmit.bind(this));
    this.$fields = this.elements.fields(this.$form, this);
    this.$fields.on('clear:errors', this.onClearFieldError);
    this.$submit = this.elements.submit(this.$form);
};
ContactForm.prototype.onFormSubmit = function(e) {
    e.preventDefault();
    this.validate();
    this.$submit.trigger('disable');
    if (this.errors) {
        this.onValidationError();
    } else {
        this.request(
            this.$form,
            this.onFormSuccess.bind(this),
            this.onFormError.bind(this));
    }
    return false;
};
ContactForm.prototype.onFormSuccess = function() {
    this.$submit.trigger('enable');
    this.$container.trigger('success');
    this.$fields.trigger('clear');
};
ContactForm.prototype.onFormError = function(res) {
    if (!!res && !!res.responseJSON)
        console.log(res.responseJSON.message);
    this.$submit.trigger('error');
};
ContactForm.prototype.onValidationError = function() {
    this.$submit.trigger('enable');
    this.$form.addClass('has-errors');
};
ContactForm.prototype.validate = function() {
    this.errors = false;
    this.$fields.trigger('valid');
};
ContactForm.prototype.containerSuccess = function() {
    $(this).addClass('success');
    setTimeout(function() {
        $container.removeClass('success');
    }, 10000)
};
ContactForm.prototype.elements = {
    container: function(selector) {
        var $container = $(selector).eq(0);
        if (!$container) throw new Error('Selector ' + selector + ' not found.');
        return $container;
    },
    form: function($container) {
        var $form = $('form', $container).eq(0);
        if (!$form) throw new Error('Form not found.');
        return $form;
    },
    fields: function($form, form) {
        var $fields = $('.form-group', $form);
        $fields.on('valid', function() {
            var $container = $(this);
            $container.trigger('clear:errors');
            var $error = $('.error-holder', $container);
            var $icon = $('.error-icon', $container);
            var $input = $('input', $container);
            if (!$input.length) $input = $('select', $container);
            if (!$input.length) $input = $('textarea', $container);
            if (!$input.length) return;
            var value = $input.eq(0).val();
            var validators = $input.attr('data-validators');
            var isValid = true,
                message;
            if (validators && validators.indexOf('email') > -1) {
                isValid = isValidEmail(value);
                message = 'Invalid email';
            }
            if (validators && validators.indexOf('required') > -1) {
                isValid = isValidString(value);
                message = 'Required field';
            }
            if (!isValid) {
                $error.html(message);
                $container.addClass('error');
                form.errors = true;
                if (!$icon.length) {
                    $error.before('<i class="icon icon-info-circle font-weight-bold error-icon"></i>');
                }
            }
        });
        $fields.on('clear', function() {
            $(this).trigger('clear:errors')
            var $input = $('input', this);
            if (!$input.length) $input = $('textarea', this);
            if (!$input.length) {
                $input = $('select', this);
                $input.parent().find('.component-select').trigger('set:default');
            }
            if ($input.attr('type') == 'radio' || $input.attr('type') == 'checkbox') {
                $input.prop('checked', false);
                if ($input.attr('type') === 'radio') {
                    $('> div:not(.form-group)', $form).removeClass('visible');
                }
            } else {
                $input.val('');
            }
        });
        $fields.on('hide', function() {
            $(this).trigger('clear');
        });
        return $fields;
    },
    submit: function($form) {
        var $submit = $('.form-submit', $form);
        var $button = $('button', $submit);
        $submit.on('disable', function() {
            $submit.addClass('disabled');
            $button.attr('disabled', true);
        });
        $submit.on('enable', function() {
            $submit.removeClass('disabled');
            $button.attr('disabled', false);
        });
        $submit.on('error', function() {
            $submit.addClass('error');
            $submit.removeClass('disabled');
            $button.attr('disabled', false);
            setTimeout(function() {
                $submit.removeClass('error');
            }, 10000);
        })
        return $submit;
    }
}
ContactForm.prototype.onClearFieldError = function() {
    var $container = $(this);
    var $error = $('> label span', $container);
    var $error = $('.error-holder', $container);
    if (!$error.length) {
        $error = $('<span></span>');
        $('> label', $container).append($error);
        $error = $('<span>').addClass('error-holder');
        $container.append($error);
    } else {
        $error.html('');
    }
    if ($container.hasClass('error')) {
        $container.removeClass('error');
    }
};
ContactForm.prototype.save = function($form) {
    var formData = this.serialize($form);
    var key = this.dataAPIKey;
    return $.ajax({
        method: 'POST',
        url: this.dataUrl,
        data: JSON.stringify(formData),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + key);
        },
    });
}
ContactForm.prototype.send = function($form) {
    var formData = this.serialize($form);
    delete formData['user-type']
  
    var key = this.emailAPIKey;
    return $.ajax({
        method: 'POST',
        url: this.emailUrl,
        data: JSON.stringify(formData),
        contentType: 'application/json',
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + key);
        },
    });
}
ContactForm.prototype.serialize = function($form) {
    var formData = $form.serializeArray().reduce(function(data, field) {
        data[field.name] = field.value;
        return data;
    }, {});
    return formData;
}
ContactForm.prototype.request = function($form, success, error) {
    var self = this;
    this.save($form)
        .then(function() {
            self.send($form);
            success();
        }, error);
}
if (!window.scrollMagicController) {
    window.scrollMagicController = new ScrollMagic.Controller({
        addIndicators: false
    });
}
$(function() {
    offsetNavigationHeight = 112;
    $.each(['hide', 'show'], function (i, ev) {
        var el = $.fn[ev];
        $.fn[ev] = function () {
          this.trigger(ev);
          return el.apply(this, arguments);
        };
    });
});

function openInNewTab(url) {
    window.open(url, '_blank').focus();
}