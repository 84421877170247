(function($) {
    $.widget('ogn.dropdownselect', {
        _create: function() {
            this.element.parent().css({ position: 'relative' });
            this.element.css({ position: 'absolute', visibility: 'hidden' });
            this.isDefaultSelected = this.element.val() === '';
            this.isMultiselect = this.element.prop('multiple');
            this.name = this.element.prop('name');
            this.options = this._getOptions();
            var urlParams = window.location.search.substr(1).split('&').reduce(function(params, segment) {
                var segments = segment.split('=');
                params[segments[0]] = segments[1];
                return params;
            }, {});
            var search = urlParams[name];
            if (search) {
                this.element.val(search);
                this.isDefaultSelected = false;
            }

            this.$select = this._createSelect();
            this.$placeholder = this._createPlaceholder();
            this.$button = this._createButton();
            this.$dropdown = this._createDropdown();
            this.$options = $($.map(this.options, this._createOption.bind(this)));
            this.element.after(this.$select);
        },
        _init: function() {
            this.$placeholder.on('click', this._onToggleSelect.bind(this));
            this.$button.on('click', this._onToggleSelect.bind(this));
            this.$select.on('close', this._handleClose.bind(this));
            this.$select.on('change', this._handleChange.bind(this));
            this.$select.on('clear set:default', this._handleClear.bind(this));
            this.$options.on('click', this._handleChoose.bind(this));
            this.window.on('click resize', this._handleWindow.bind(this));
        },
        _getOptions: function() {
            return this.element.children('option').map(function() {
                return { value: $(this).attr('value'), label: $(this).html(), selected: $(this).is(':selected') };
            }).get();
        },
        _createSelect: function() {
            return $('<div>')
                .addClass('component-select')
                .attr('data-name', this.name);
        },
        _createPlaceholder: function() {
            return $('<span>').appendTo(this.$select);
        },
        _createButton: function() {
            return $('<button>').appendTo(this.$select);
        },
        _createDropdown: function() {
            this.$dropdownList = $('<ul>');
            return $('<div>').addClass('select-variants').append(this.$dropdownList).appendTo(this.$select);
        },
        _createOption: function(option, index) {
            var $option = $('<li>')
                .data('index', index)
                .data('value', option.value)
                .data('label', option.label)
                .html(option.label);
            if (option.selected) {
                $option.addClass('selected');
                this.$placeholder.html(option.label);
            }
            if (!!option.value.length) {
                return $option.appendTo(this.$dropdownList).get(0);
            }
            return null;
        },
        _onToggleSelect: function(event) {
            event.preventDefault();
            $('.component-select').each(this._closeOtherDropdowns.bind(this));
            this.$dropdown.toggleClass('active');
            return false;
        },
        _handleClose: function(event) {
            event.preventDefault();
            this.$dropdown.removeClass('active');
            return false;
        },
        _handleWindow: function() {
            this.$dropdown.removeClass('active');
        },
        _handleChoose: function(event) {
            event.preventDefault();
            this._choose($(event.currentTarget));
            this.element.change();
            return false;
        },
        _handleChange: function(event, value) {
            event.preventDefault();
            var $option = this.$options.filter(function() {
                return $(this).data('value') === value;
            }).first();
            if ($option.length) {
                this._choose($option)
            }
            return false;
        },
        _handleClear: function() {
            if (this.options.length) {
                this.$options.removeClass('active');
                this.$options.filter('.selected').removeClass('selected');
                this.$options.first().addClass('selected');
                this.element.val(this.options[0]['value']);
                this.$placeholder.html(this.options[0]['label']);
            }
        },
        _choose: function($option) {
            this.$options.filter('.selected').removeClass('selected');
            $option.addClass('selected');
            this.element.val($option.data('value'));
            this.$placeholder.html($option.data('label'));
            this.$dropdown.removeClass('active');
        },
        _closeOtherDropdowns: function(index, element) {
            if(!this.$select.is($(element))) {
                $(element).trigger('close');
            }
        },
        rebuild: function() {
            this.$select.remove();

            this.options = this._getOptions();

            this.$select = this._createSelect();
            this.$placeholder = this._createPlaceholder();
            this.$button = this._createButton();
            this.$dropdown = this._createDropdown();
            this.$options = $($.map(this.options, this._createOption.bind(this)));
            this.element.after(this.$select);

            this.$placeholder.on('click', this._onToggleSelect.bind(this));
            this.$button.on('click', this._onToggleSelect.bind(this));
            this.$select.on('close', this._handleClose.bind(this));
            this.$select.on('change', this._handleChange.bind(this));
            this.$select.on('clear set:default', this._handleClear.bind(this));
            this.$options.on('click', this._handleChoose.bind(this));
        }
    })
    $('.form-select').dropdownselect();
})(jQuery);