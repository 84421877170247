(function($) {
    $.widget( "ogn.jumbotron", {
        _create: function() {
            this.$carousel = $('#carousel', this.element);
            this.cookieName = 'carousel_slide';
            this.cookieExpiration = 365 * 20;
        },
        _init: function() {
            // Uncomment once a particular slide should appear first on the 1st visit
            // if (this._getCookie(this.cookieName)) {
            //     this._initCarousel(1);
            // } else {
            //     this._setCookie(this.cookieName, 1, this.cookieExpiration);
            //     this._initCarousel(0);
            // }
            this._initCarousel(0);
        },
        _initCarousel: function(slide) {
            var nthChild = slide + 1;
            this.$carousel.find('.carousel-indicators li:nth-child(' + nthChild + ')').addClass('active');
            this.$carousel.find('.carousel-inner .carousel-item:nth-child(' + nthChild + ')').addClass('active');
            this.$carousel.carousel(slide);
            this.$carousel.addClass('slide');
        },
        _setCookie: function(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        _getCookie: function(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
    });
    $(document).ready(function() {
        $('.jumbotron').jumbotron();
    });
})(jQuery);