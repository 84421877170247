(function($) {
    $.widget('ogn.parallax', {
        options: {
            triggerHook: 0.75,
            logLevel: 3,
        },
        _create: function() {
            var $tabsContainers = $('.home-parallax', this.element);
            if ($tabsContainers.length > 0) {
                $tabsContainers.each(this._createParallaxEffect.bind(this));
            }
        },
        _createParallaxEffect: function(index, element) {
            var configs = {
                triggerElement: $(element),
                triggerHook: this.options.triggerHook,
                logLevel: this.options.logLevel,
            }
            this._addParallax($(element), configs)
        },
        _addParallax: function($element, configs) {
            new $.ScrollMagic.Scene(configs).setClassToggle($element, 'visible').addTo(window.scrollMagicController);
        }
    })
    $('.template-home').parallax();
})(jQuery);