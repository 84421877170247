$(function() {
    if ($('#video-dr-pons').length) {
        var player = videojs('video-dr-pons');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'DR. PONS Video', 'value':'PLAY'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'DR. PONS Video', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'DR. PONS Video', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'DR. PONS Video', 'value':'COMPLETE'});
		});
    }
	
	if ($('#video-product-application').length) {
        var player = videojs('video-product-application');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'APPLYING PURAPLY AM', 'value':'PLAY'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'APPLYING PURAPLY AM', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'APPLYING PURAPLY AM', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'APPLYING PURAPLY AM', 'value':'COMPLETE'});
		});
    }

	if ($('#video-respond').length) {
        var player = videojs('video-respond');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true,
			'90': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'RESPOND Study Video', 'value':'PLAY'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'RESPOND Study Video', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'RESPOND Study Video', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'RESPOND Study Video', 'value':'COMPLETE'});
		});
    }

	if ($('#video-moa').length) {
		var player = videojs('video-moa');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'MOA', 'value':'PLAY'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'MOA', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'MOA', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'MOA', 'value':'COMPLETE'});
		});
	}

	if ($('#video-control-panel-episode-1').length) {
		var player = videojs('video-control-panel-episode-1');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 1', 'value':'PLAY'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'CONTROL PANEL EPISODE 1', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 1', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 1', 'value':'COMPLETE'});
		});
	}

	if ($('#video-control-panel-episode-2').length) {
		var player = videojs('video-control-panel-episode-2');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 2', 'value':'PLAY'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'CONTROL PANEL EPISODE 2', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 2', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 2', 'value':'COMPLETE'});
		});
	}

	if ($('#video-control-panel-episode-3').length) {
		var player = videojs('video-control-panel-episode-3');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 3', 'value':'PLAY'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'CONTROL PANEL EPISODE 3', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 3', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 3', 'value':'COMPLETE'});
		});
	}

	if ($('#video-control-panel-episode-4').length) {
		var player = videojs('video-control-panel-episode-4');
		var markers = {
			'10': true,
			'25': true,
			'50': true,
			'75': true
		}
		player.on('play', function() {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 4', 'value':'PLAY'});
		});
		player.on('timeupdate', function(e) {
			var percentPlayed = Math.floor(this.currentTime()*100/this.duration()).toString();
			if (markers[percentPlayed]) {
				markers[percentPlayed] = false;
				dataLayer.push({'event':'CONTROL PANEL EPISODE 4', 'value': percentPlayed+'%'});
			}
		});
		player.on('pause', function(e) {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 4', 'value':'STOP'});
		});
		player.on('ended', function() {
			dataLayer.push({'event':'CONTROL PANEL EPISODE 4', 'value':'COMPLETE'});
		});
	}
});

$('.play-video').on('click', function(e) {
	var videoId = $(this).data('video-id');
	var player = videojs(videoId);
	if (player.paused()) {
		player.play();
	} else {
		player.pause();
	}
	e.preventDefault();
});
